<template lang="pug">
v-container
  v-row
    v-col(cols="12")
      h2 Estadísticas de compras
      v-divider.mt-8.mb-4
    v-col(cols="4")
      v-autocomplete(
        v-model="form.sku"
        :items="productList"
        item-value="sku"
        item-text="name"
        label="Producto"
        outlined
        dense
        clearable
        @change="dataLoaded = false"
      )
    v-col(cols="2")
      v-text-field(v-model="form.start_date" type="date" label="Fecha inicio" outlined dense)
    v-col(cols="2")
      v-text-field(v-model="form.end_date" type="date" label="Fecha final" outlined dense)
    v-col(cols="2")
      v-select(
        v-model="form.period"
        :items="periods"
        item-text="text"
        item-value="value"
        label="Periodicidad"
        outlined
        dense
      )
    v-col(cols="2")
      v-btn(color="primary" block @click="fetchKPIData") Cargar
  v-row
    v-col(cols="12")
      v-card
        v-card-title Resumen de Compras
        v-card-text
          template(v-if="dataLoaded")
            template(v-if="form.sku")
              v-row
                v-col(cols="4")
                  v-card(class="kpi-card")
                    v-card-title.justify-center Monto Total Gastado
                    v-card-subtitle.text-h6 S/ {{ totalSpent | numberFormat }}
                v-col(cols="4")
                  v-card(class="kpi-card")
                    v-card-title.justify-center Ticket Promedio
                    v-card-subtitle.text-h6 S/ {{ averageTicket | numberFormat }}
                v-col(cols="4")
                  v-card(class="kpi-card")
                    v-card-title.justify-center Cantidad Comprada
                    v-card-subtitle.text-h6 {{ totalQuantity }} {{ unit }}
              v-row
                v-col(cols="12")
                  apexchart(
                    type="line"
                    height="350"
                    :options="costGrowthChartOptions"
                    :series="costGrowthChartData"
                  )
                v-col(cols="12")
                  v-card
                    v-card-title Variación de precio
                    v-card-text
                      v-data-table(
                        :headers="skuSalesHeaders"
                        :items="skuSales"
                        class="elevation-1"
                      )
                        template(v-slot:item.growth="{ item }")
                          span(:class="['font-weight-bold', 'text-right', item.growth >= 0 ? 'red--text':'green--text']")  {{ item.growth | percentageFormat }}
                        template(v-slot:item.purchase_timestamp="{ item }")
                          | {{ formatPurchaseDate(item.purchase_timestamp) }}
                        template(v-slot:item.quantity="{ item }")
                          | {{ item.quantity }} {{ seletecProduct.unit }}
                        template(v-slot:item.unit_price="{ item }")
                          | S/ {{ item.unit_price | numberFormat }}
                        template(v-slot:item.total_cost="{ item }")
                          | S/ {{ item.total_cost | numberFormat }}
            template(v-else)
              v-row
                v-col(cols="4")
                  v-card(class="kpi-card")
                    v-card-title.justify-center Monto Total Gastado
                    v-card-subtitle.text-h6 S/ {{ totalSpent | numberFormat }}
                v-col(cols="4")
                  v-card(class="kpi-card")
                    v-card-title.justify-center Número de Compras
                    v-card-subtitle.text-h6 {{ totalSales }}
                v-col(cols="4")
                  v-card(class="kpi-card")
                    v-card-title.justify-center Cantidad Comprada
                    v-card-subtitle.text-h6
                      ul
                        li(v-for="(quantity, unit) in totalQuantities" :key="unit")
                          | {{ quantity }} {{ unit }}
              v-row
                v-col(cols="12")
                  v-card
                    v-card-title Productos que más aumentaron de precio
                    v-card-text
                      v-data-table(
                        :headers="increaseHeaders"
                        :items="topPriceIncreaseProducts"
                        class="elevation-1"
                      )
                        template(v-slot:item.growth="{ item }")
                          span(:class="['font-weight-bold', 'text-right', item.growth >= 0 ? 'red--text':'green--text']") {{ item.growth | percentageFormat }}
                        template(v-slot:item.firstPrice="{ item }")
                          | S/ {{ item.firstPrice | numberFormat }}
                        template(v-slot:item.lastPrice="{ item }")
                          | S/ {{ item.lastPrice | numberFormat }}
                v-col(cols="12")
                  v-card
                    v-card-title Productos que más bajaron de precio
                    v-card-text
                      v-data-table(
                        :headers="decreaseHeaders"
                        :items="topPriceDecreaseProducts"
                        class="elevation-1"
                      )
                        template(v-slot:item.growth="{ item }")
                          span(:class="['font-weight-bold', 'text-right', item.growth >= 0 ? 'red--text':'green--text']") {{ item.growth | percentageFormat }}
                        template(v-slot:item.firstPrice="{ item }")
                          | S/ {{ item.firstPrice | numberFormat }}
                        template(v-slot:item.lastPrice="{ item }")
                          | S/ {{ item.lastPrice | numberFormat }}
          template(v-else)
            v-row
              v-col(cols="12")
                v-alert(type="info" text center)
                  | Presione "Cargar" para obtener los datos.
  </template>
  
  <script>
  export default {
    computed: {
      productList() {
        return this.$store.state.products.productsSimple;
      },
      seletecProduct() {
        return this.productList.find((p) => p.sku === this.form.sku);
      },
    },
    data() {
      return {
        dataLoaded: false,
        totalSales: 0,
        totalSpent: 0,
        averageTicket: 0,
        totalQuantity: 0,
        unit: '',
        skuSales: [],
        totalQuantities: {},
        periods: [
          { text: 'Diario', value: 'daily' },
          { text: 'Semanal', value: 'weekly' },
          { text: 'Mensual', value: 'monthly' },
        ],
        form: {
          sku: null,
          start_date: null,
          end_date: null,
          period: 'daily',
        },
        costGrowthChartOptions: {
          chart: {
            id: 'cost-growth-chart',
            toolbar: {
              show: false,
            },
          },
          xaxis: {
            categories: [],
          },
          yaxis: [
            {
              title: {
                text: 'Costo Unitario Promedio',
              },
              labels: {
                formatter: function (value) {
                  return 'S/ ' + value.toFixed(2);
                },
              },
            },
          ],
          title: {
            text: 'Variación del Costo Unitario en el Tiempo',
            align: 'center',
          },
          tooltip: {
            y: {
              formatter: function (value) {
                return 'S/ ' + value.toFixed(2);
              },
            },
          },
        },
        costGrowthChartData: [],
        increaseHeaders: [
          { text: 'SKU', value: 'sku' },
          { text: 'Producto', value: 'name' },
          { text: 'Precio Inicial', value: 'firstPrice', align: 'right' },
          { text: 'Periodo Inicial', value: 'firstPeriod' },
          { text: 'Precio Final', value: 'lastPrice', align: 'right' },
          { text: 'Periodo Final', value: 'lastPeriod' },
          { text: 'Variación (%)', value: 'growth', align: 'right' },
        ],
        decreaseHeaders: [
          { text: 'SKU', value: 'sku' },
          { text: 'Producto', value: 'name' },
          { text: 'Precio Inicial', value: 'firstPrice', align: 'right' },
          { text: 'Periodo Inicial', value: 'firstPeriod' },
          { text: 'Precio Final', value: 'lastPrice', align: 'right' },
          { text: 'Periodo Final', value: 'lastPeriod' },
          { text: 'Variación (%)', value: 'growth', align: 'right' },
        ],
        skuSalesHeaders: [
          { text: 'Proveedor', value: 'supplier' },
          { text: 'Fecha compra', value: 'purchase_timestamp' },
          { text: 'Cantidad', value: 'quantity', align: 'center' },
          { text: 'Costo Unitario', value: 'unit_price' },
          { text: 'Costo total', value: 'total_cost', align: 'center' },
          { text: 'Variación (%)', value: 'growth', align: 'center' },
        ],
        topPriceIncreaseProducts: [],
        topPriceDecreaseProducts: [],
        skuVariationData: [],
      };
    },
    created() {
      const today = new Date();
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(today.getDate() - 7);
  
      const formatDate = (date) =>
        date.toISOString().split('T')[0]; // Formato YYYY-MM-DD
  
      this.form.start_date = formatDate(sevenDaysAgo);
      this.form.end_date = formatDate(today);
      this.$store.dispatch('loadProductsSimple');
    },
    methods: {
      formatPurchaseDate(timestamp) {
        const date = new Date(timestamp * 1000);
        return new Intl.DateTimeFormat("es-PE", {
          dateStyle: "short",
          timeStyle: "short",
          timeZone: "America/Lima",
        }).format(date);
      },
      async fetchKPIData() {
        try {
          this.dataLoaded = false; // Evitar mostrar datos anteriores mientras se cargan nuevos
          // Fetch data from backend
          const salesSummaryResponse = await this.$store.dispatch(
            'purchaseKpisSalesSummary',
            this.form
          );
          const unitCostGrowthResponse = await this.$store.dispatch(
            'purchaseKpisUnitCostGrowth',
            this.form
          );
          
  
          if (this.form.sku) {
            // SKU seleccionado
            const sku = this.form.sku;
            const salesData = salesSummaryResponse.data.find(
              (item) => item.sku === sku
            );
            if (salesData) {
              this.totalSpent = salesData.total_spent;
              this.totalSales = salesData.total_sales;
              this.averageTicket =
                salesData.total_spent / salesData.total_sales || 0;
              this.totalQuantity = salesData.total_quantity;
              this.unit = salesData.unit;
            } else {
              this.totalSpent = 0;
              this.totalSales = 0;
              this.averageTicket = 0;
              this.totalQuantity = 0;
              this.unit = '';
            }
            console.log(unitCostGrowthResponse)
            // Preparar datos para el gráfico
            const skuUnitCostGrowthData = JSON.parse(JSON.stringify(unitCostGrowthResponse));
            skuUnitCostGrowthData.sort(
              (a, b) =>
                new Date(this.getDateField(a)) - new Date(this.getDateField(b))
            );
            console.log(skuUnitCostGrowthData)
            this.costGrowthChartData = [
              {
                name: 'Costo Unitario Promedio',
                data: skuUnitCostGrowthData.map(
                  (entry) => entry.average_unit_cost
                ),
              },
            ];
            this.costGrowthChartOptions.xaxis.categories = skuUnitCostGrowthData.map(
              (entry) => {
                const dateStr = this.getDateField(entry);
                return this.formatDateForXAxis(dateStr, this.form.period);
              }
            )

            this.skuSales = await this.$store.dispatch('purchaseKpisSalesBySku', this.form);
            this.skuSales.sort((a, b) => new Date(b.purchase_timestamp) - new Date(a.purchase_timestamp));
            this.skuSales.forEach((entry, index) => {
              const nextEntry = this.skuSales[index + 1];
              if (nextEntry) {
                const growthPercentage = ((nextEntry.unit_price - entry.unit_price) / entry.unit_price) * 100;
                entry.growth = growthPercentage;
              } else {
                entry.growth = 0;
              }
            })
            console.log(this.skuSales)
          } else {
            // Sin SKU seleccionado
            this.totalSpent = salesSummaryResponse.data.reduce(
              (sum, item) => sum + item.total_spent,
              0
            );
            this.totalSales = salesSummaryResponse.data.reduce(
              (sum, item) => sum + item.total_sales,
              0
            );
            const quantitiesByUnit = {};
            salesSummaryResponse.data.forEach((item) => {
              if (!quantitiesByUnit[item.unit]) {
                quantitiesByUnit[item.unit] = 0;
              }
              quantitiesByUnit[item.unit] += item.total_quantity;
            });
            this.totalQuantities = quantitiesByUnit;
  
            // Preparar datos para las tablas
            const growthDataBySku = {};
            unitCostGrowthResponse.forEach((entry) => {
              if (!growthDataBySku[entry.sku]) {
                growthDataBySku[entry.sku] = [];
              }
              growthDataBySku[entry.sku].push(entry);
            });
  
            const productsGrowth = [];
            for (const sku in growthDataBySku) {
              const growthEntries = growthDataBySku[sku];
              growthEntries.sort(
                (a, b) =>
                  new Date(this.getDateField(a)) -
                  new Date(this.getDateField(b))
              );
              const firstEntry = growthEntries[0];
              const lastEntry = growthEntries[growthEntries.length - 1];
              const firstPrice = firstEntry.average_unit_cost;
              const lastPrice = lastEntry.average_unit_cost;
              const growthPercentage =
                ((lastPrice - firstPrice) / firstPrice) * 100;
              productsGrowth.push({
                sku: sku,
                name: this.getProductName(sku),
                firstPeriod: this.formatDateForXAxis(
                  this.getDateField(firstEntry),
                  this.form.period
                ),
                firstPrice: firstPrice,
                lastPeriod: this.formatDateForXAxis(
                  this.getDateField(lastEntry),
                  this.form.period
                ),
                lastPrice: lastPrice,
                growth: growthPercentage,
              });
            }
  
            this.topPriceIncreaseProducts = productsGrowth
              .filter((p) => p.growth > 0)
              .sort((a, b) => b.growth - a.growth)
              .slice(0, 10);
            this.topPriceDecreaseProducts = productsGrowth
              .filter((p) => p.growth < 0)
              .sort((a, b) => a.growth - b.growth)
              .slice(0, 10);
          }
  
          this.dataLoaded = true; // Indicar que los datos se han cargado
        } catch (error) {
          console.error('Error fetching KPI data:', error);
        }
      },
      getDateField(entry) {
        if (this.form.period === 'daily') {
          return entry.period;
        } else if (this.form.period === 'weekly') {
          return entry.start_of_week;
        } else if (this.form.period === 'monthly') {
          return entry.start_of_month;
        } else {
          return entry.period;
        }
      },
      formatDateForXAxis(dateStr, period) {
        if (!dateStr) return '';
        const dateObj = new Date(dateStr + 'Z'); // Append 'Z' to indicate UTC time
        if (period === 'daily') {
          return `${('0' + dateObj.getUTCDate()).slice(-2)}-${(
        '0' +
        (dateObj.getUTCMonth() + 1)
          ).slice(-2)}`;
        } else if (period === 'weekly') {
          return `${('0' + dateObj.getUTCDate()).slice(-2)}-${(
        '0' +
        (dateObj.getUTCMonth() + 1)
          ).slice(-2)}`;
        } else if (period === 'monthly') {
          return `${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}-${dateObj.getUTCFullYear()}`;
        } else {
          return dateStr;
        }
      },
      getProductName(sku) {
        const product = this.productList.find((p) => p.sku === sku);
        return product ? product.name : sku;
      },
    },
    filters: {
      numberFormat(value) {
        return new Intl.NumberFormat('es-PE', {
          style: 'decimal',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(value);
      },
      percentageFormat(value) {
        return (
          new Intl.NumberFormat('es-PE', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(value) + '%'
        );
      },
    },
  };
  </script>
  
  <style scoped>
  .kpi-card {
    text-align: center;
    margin-bottom: 20px;
  }
  .text--primary {
    color: red;
  }
  .text--secondary {
    color: green;
  }
  </style>
  