<template>
  <v-navigation-drawer
    floating
    v-model="drawer"
    permanent
    stateless
    color="primary"
    app
  >
    <v-list dense>
      <v-list-item class="text-center mx-0 mt-5 mb-9 justify-center">
        <img
          width="150px"
          :src="require('../../assets/logo-horizontal-blanco.png')"
        />
      </v-list-item>
      <template v-for="item in menuItems">
        <template v-if="canWatchSection(item.section)">
          <v-list-item
            :key="item.title"
            dense
            :to="item.path"
            color="white"
            v-if="!Boolean(item.items)"
          >
            <div class="top-corner"></div>
            <v-list-item-action>
              <v-icon>{{ item.action }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="ml-4">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
            <div class="bottom-corner"></div>
          </v-list-item>

          <v-list-group
            v-else
            :key="item.title"
            :prepend-icon="item.action"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.items"
              :key="child.title"
              :to="child.path"
              class=".item-child"
            >
              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
      </template>
    </v-list>
    <template v-slot:append>
      <v-list dense>
        <v-list-item @click="logOut">
          <v-list-item-action>
            <v-icon>mdi-power</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Cerrar sesión</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      generalMenu: [
        /*{
          title: "Pedidos ",
          action: "mdi-cart",
          path: "/pedidos",
          section: "pedidos",
        },*/
        {
          title: "Categorías",
          action: "mdi-view-dashboard",
          path: "/categorias",
          section: "categorias",
        },
        {
          title: "Materia Prima",
          action: "mdi-view-dashboard",
          path: "/materia-prima",
          section: "productos",
        },
        {
          title: "Producto final",
          action: "mdi-view-dashboard",
          path: "/producto-final",
          section: "productos",
        },
        /*
        {
          title: "Clientes",
          action: "mdi-account-multiple",
          path: "/clientes",
          section: "clientes",
        },*/

        {
          title: "Corporativo",
          action: "mdi-domain",
          path: "/corporativo",
          section: "corporativo",
          items: [
            { title: "Empresas/Clientes", path: "/corporativo/empresas" },
            { title: "Plantillas", path: "/corporativo/plantillas" },
            { title: "Hubs", path: "/corporativo/hubs" },
            { title: "Almacenes", path: "/corporativo/almacenes" },
            { title: "Rutas", path: "/corporativo/rutas" },
            { title: "Órdenes", path: "/corporativo/ordenes" },
            { title: "Combinar órdenes", path: "/corporativo/combinar-ordenes" },
          ],
        }, 
        {
          title: "Proveedores",
          action: "mdi-account-box",
          path: "/proveedores",
          section: "proveedores",
        },
        {
          title: "Compras",
          action: "mdi-basket-fill",
          path: "/compras",
          section: "compras",
          items: [
            { title: "Operaciones", path: "/compras" },
            { title: "KPIs", path: "/compras/kpis" },
            
          ],
        },
        {
          title: "Transportistas",
          action: "mdi-truck",
          path: "/transportistas",
          section: "transportistas",
        },
        {
          title: "Usuarios",
          action: "mdi-account-circle",
          path: "/usuarios",
          section: "usuarios",
        },
        {
          title: "Picker",
          action: "mdi-account-circle",
          path: "/pickers",
          section: "corporativo",
        },
        {
          title: "Picking",
          action: "mdi-rhombus-split",
          path: "/picking",
          section: "corporativo",
        },
        {
          title: "Inventario",
          action: "mdi-package-variant",
          path: "/inventario",
          section: "corporativo",
        },

        {
          title: "Cobranzas",
          action: "mdi-cash",
          path: "/cobranza",
          section: "cobranza",
        },
        {
          title: "Configuración",
          action: "mdi-cog",
          path: "/configuracion",
          section: "configuracion",
        }, 
      ],
    };
  },
  computed: {
    drawer: {
      set(show) {
        this.$store.commit("changeDrawer", show);
      },
      get() {
        return this.$store.state.drawer;
      },
    },
    menuItems() {
      let selectedBusiness = this.$store.state.business.selectedBusiness;
      if (selectedBusiness) {
        return this.businessMenu;
      } else {
        return this.generalMenu;
      }
    },
  },
  methods: {
    canWatchSection(section) {
      return this.$store.getters.canWatchSection(section);
    },
    async logOut() {
      await this.$store.dispatch("logOut");
    },
  },
};
</script>

<style lang="scss">
.v-navigation-drawer {
  .v-list-item {
    color: white !important;
    //padding: 0;
    margin: 0 0 0 16px;
    font-style: normal;
    font-weight: 900;
    font-size: 10px;
    line-height: 24px;

    letter-spacing: 1px;
    .top-corner {
      position: absolute;
    }
    .bottom-corner {
      position: absolute;
    }
    &.v-list-item--active::before {
      opacity: 0;
    }
    &:not(.v-list-item--active):not(.v-list-item--disabled) {
      color: white !important;
    }
    &:not(.v-list-group__header).v-list-item--active {
      background-color: white;
      border-top-left-radius: 32px;
      border-bottom-left-radius: 32px;
      color: #561fdb !important;
      &:hover::before {
        opacity: 0 !important;
      }
      .top-corner {
        position: absolute;
        top: -30px;
        right: 0;
        height: 30px;
        width: 30px;
        border-bottom-right-radius: 50%;
        background: #561fdb;
        box-shadow: 0px 10px 0px 0px white;
      }
      .bottom-corner {
        position: absolute;
        bottom: -30px;
        right: 0;
        height: 30px;
        width: 30px;
        border-top-right-radius: 50%;
        background: #561fdb;
        box-shadow: 10px 0px 0px 0px white;
      }
    }
  }
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: white !important;
    .v-icon {
      color: white !important;
    }
  }
}
</style>
