import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import ForgetPassword from '../views/ForgetPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'

//
import Suppliers from '../views/dashboard/suppliers/index.vue'
import SuppliersView from '../views/dashboard/suppliers/supplierView.vue'
import Carriers from '../views/dashboard/carriers/index.vue'
import CarriersView from '../views/dashboard/carriers/carrierView.vue'
import Categories from '../views/dashboard/Categories.vue'
import RawProductList from '../views/dashboard/RawProductList.vue'
import ProductView from '../views/dashboard/ProductView.vue'
import FinalProductView from '../views/dashboard/FinalProductView.vue'
import FinalProductList from '../views/dashboard/FinalProductList.vue'
import Orders from '../views/dashboard/Orders.vue'
import Configuration from '../views/dashboard/Configuration.vue'
import Customers from '../views/dashboard/Customers.vue'
import DebtCollect from '../views/dashboard/DebtCollect.vue'


import Users from '../views/dashboard/Users'
import UsersEdit from '../views/dashboard/Users/Edit'

import BusinessIndex from '../views/dashboard/business/index.vue'
import listBusinesses from '../views/dashboard/business/listBusinesses.vue'
import templateProducts from '../views/dashboard/business/templateProducts.vue'
import HubsList from '../views/dashboard/business/HubsList.vue'
import WarehousesList from '../views/dashboard/business/WarehouseList.vue'
import Routes from '../views/dashboard/business/routesList.vue'
import reviewRoute from '../views/dashboard/business/reviewRoute.vue'
import notaCreditoOrder from '../views/dashboard/business/notaCreditoOrder.vue'
import combineOrdersList from '../views/dashboard/business/combineOrdersList.vue'

import businessOrdersList from '../views/dashboard/business/businessOrdersList.vue'
import businessOrderAction from '../views/dashboard/business/businessOrderAction.vue'
import listInventory from '../views/dashboard/business/listInventory.vue'
import inventoryMovements from '../views/dashboard/business/inventoryMovements.vue'
import inventoryMovementsMassive from '../views/dashboard/business/inventoryMovementsMassive.vue'

import businessUsersList from '../views/dashboard/business/businessUsersList.vue'
import pickerList from '../views/dashboard/pickerList.vue'

import purchasesList from '../views/dashboard/business/purchasesList.vue'
import purchaseView from '../views/dashboard/business/purchaseView.vue'
import purchaseKpis from '../views/dashboard/business/purchaseKpis.vue'

import pickingView from '../views/dashboard/business/pickingView.vue'
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Home,
    children:[
      {
        path:"proveedores",
        component: Suppliers
      },
      {
        path:"proveedores/:id",
        component: SuppliersView
      },
      {
        path:"transportistas",
        component: Carriers
      },
      {
        path:"transportistas/:id",
        component: CarriersView
      },
      {
        path:"categorias",
        component: Categories
      },
      {
        path:'materia-prima',
        component: RawProductList
      },
      {
        path:'materia-prima/:productID',
        component: ProductView
      },
      {
        path: 'materia-prima/:sku/inventario',
        component: inventoryMovements,
      },
      {
        path:'producto-final',
        component: FinalProductList
      },
      {
        path:'producto-final/:productID',
        component: FinalProductView
      },

      {
        path: 'producto-final/:sku/inventario',
        component: inventoryMovements,
      },
      {
        path:"pedidos",
        component: Orders
      },
      {
        path:"configuracion",
        component: Configuration
      },  
      {
        path:"clientes",
        component: Customers
      },  
      {
        path:"inventario",
        component: listInventory
      },
      {
        path: "inventario/movimientos-masivo",
        component: inventoryMovementsMassive
      },
      {
        path:"usuarios",
        component: Users
      },
      {
        path: 'pickers',
        component: pickerList
      },
      {
        path: 'picking',
        component: pickingView,
      },
      {
        path:"usuarios/accion",
        component: UsersEdit
      },
      {
        path: 'corporativo/empresas',
        component: listBusinesses
      },
      {
        path: 'corporativo/plantillas',
        component: templateProducts
      },{
        path:'corporativo/hubs',
        component: HubsList
      },
      {
        path: 'corporativo/almacenes',
        component: WarehousesList
      },
      {
        path: 'corporativo/rutas',
        component: Routes
      },
      {
        path: 'corporativo/rutas/:id/revisar',
        component: reviewRoute
      },
      {
        path: 'corporativo/ordenes/:id/notacredito',
        component: notaCreditoOrder
      },
      {
        path: 'corporativo/ordenes',
        component: businessOrdersList
      },
      {
        path: 'corporativo/ordenes/:id',
        component: businessOrderAction
      },
      {
        path: 'corporativo/combinar-ordenes',
        component: combineOrdersList
      },
      {
        path: 'corporativo/empresas/:businessId',
        component: businessUsersList
      },
      {
        path: 'cobranza',
        component: DebtCollect
      },
      {
        path: 'compras',
        component: purchasesList
      },
      {
        path: 'compras/kpis',
        component: purchaseKpis
      },
      {
        path: 'compras/:id',
        component: purchaseView
      },
      
    ]
  },
  {
    path: "/forget-password",
    component: ForgetPassword,
  },
  {
    path: "/reset-password",
    component: ResetPassword,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  
})

export default router;
